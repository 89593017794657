import {mapActions, mapGetters, mapMutations} from "vuex";
//sections
import mainButton from '@/components/main-button/index.vue'


export default {
    name: "bonuses",
    components: {
        mainButton
    },
    data() {
        return {
            legal: true,
        }
    },

    created() {
        this.fetchDiscounts()
        this.fetchDiscountDescription()
    },
    computed: {
        ...mapGetters({
            profileRequestsFlag: 'profile/isLoadingProfile',
            discountList: 'profile/discounts',
            discountDescription: 'profile/discountDescription'
        })
    },

    methods: {

        ...mapMutations({}),
        ...mapActions({
            fetchDiscounts: 'profile/GET_DISCOUNTS',
            fetchDiscountDescription: 'profile/GET_DISCOUNT_DESCRIPTION',
        })
    }
}
